import { City } from "@interfaces/response/cities.interface";
import { useQuery } from "@tanstack/react-query";
import { ICitiesListFilters, getCities } from "api/cities.request";
import { debounce } from "lodash";

export default function useCities(
  filters: ICitiesListFilters = { q: "" },
  debounce: number = 0
) {
  const haSH = JSON.stringify(getFilter(filters));
  return useQuery(["citiesList", haSH], () => getCities(filters), {
    cacheTime: Infinity,
    staleTime: Infinity,
    select: (response) => [
      {
        id: 1,
        description: "DISTRITO NACIONAL",
      } as City,
      ...response.data,
    ],
  });
}

const getFilter = debounce((e: any) => {
  return e;
}, 200);
